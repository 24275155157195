<template>
  <section>
    <v-toolbar flat>
      <v-col lg="3" md="3" sm="12" class="pb-0">
        <v-text-field
          label="Search Devices"
          append-icon="search"
          class="pb-0 pt-2"
          v-model="search"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2" @click="createDevice()">
        Create Device
      </v-btn>
      <!-- basic setting for adding device New -->
    </v-toolbar>
    <v-tabs>
      <v-tab @click="getDeviceList('SOFTPHONE')">
        <v-icon left> dialer_sip </v-icon>
        Sip devices
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on"
              ><v-icon color="green">refresh</v-icon></v-btn
            >
          </template>
          <span>Refresh Sip Devices</span>
        </v-tooltip>
      </v-tab>
      <v-tab @click="getDeviceList('CELLPHONE')">
        <v-icon left> phone_iphone </v-icon>
        Regular Phones
      </v-tab>

      <v-tab-item>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="deviceListArray"
          :loading="tableLoader"
        >
          <template v-slot:[`item.indexNo`]="{ item }">
            {{ deviceListArray.indexOf(item) + 1 }}
          </template>
          <!-- <template v-slot:[`item.device_type`]="{ item }">
            {{ item.device_type == "softphone" ? "Sip Device" : "" }}
          </template> -->
          <template v-slot:[`item.id`]="{ item }">
            <v-avatar
              :color="item.id | getDeviceStatus"
              size="9"
              class="ml-1"
            ></v-avatar>
          </template>
          <template v-slot:[`item.username`]="{ item }">
            {{ item.username ? item.username : "-" }}
          </template>
          <template v-slot:[`item.viewAction`]="{ item }">
            <v-btn x-small outlined fab @click="editItem(item)" color="teal">
              <v-icon small> mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              @click="deleteItem(item)"
              fab
              x-small
              color="error"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="deviceListArray"
        >
          <template v-slot:[`item.indexNo`]="{ item }">
            {{ deviceListArray.indexOf(item) + 1 }}
          </template>
          <!-- <template v-slot:[`item.device_type`]="{ item }">
            {{
              item.device_type == "cellphone" ? "Cell Phone" : item.device_type
            }}
          </template> -->
          <!-- <template v-slot:[`item.id`]="{ item }">
            <v-avatar
              :color="item.id | getDeviceStatus"
              size="9"
              class="ml-1"
            ></v-avatar>
          </template> -->
          <!-- <template v-slot:[`item.username`]="{ item }">
            {{ item.username ? item.username : "-" }}
          </template> -->
          <template v-slot:[`item.viewAction`]="{ item }">
            <v-btn x-small outlined fab @click="editItem(item)" color="teal">
              <v-icon small> mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              @click="deleteItem(item)"
              fab
              x-small
              color="error"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>

    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title>
          <span class="headline">Create Device</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0 mb-0">
          <v-select
            :items="optionSelectValues"
            item-text="text"
            item-value="value"
            class="pt-4"
            v-model="AddDeviceDataJson.device_type"
            v-on:change="onChange(AddDeviceDataJson.device_type)"
            label="Select device"
            outlined
          >
          </v-select>
          <v-text-field
            v-model="AddDeviceDataJson.sipDeviceName"
            v-show="deviceShow"
            outlined
            label="Device Name"
          ></v-text-field>
          <v-text-field
            v-if="callphoneShow"
            v-model="AddDeviceDataJson.number"
            placeholder="Enter Complete number with country code"
            label="Number"
            outlined
          ></v-text-field>
          <!-- <v-text-field
            v-if="callphoneShow"
            v-model="AddDeviceDataJson.userName"
            disabled
            label="Username"
            outlined
          ></v-text-field>
          <v-text-field
            v-if="callphoneShow"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="AddDeviceDataJson.password"
            :type="show2 ? 'text' : 'password'"
            name="input-10-2"
            label="Visible"
            value="wqfasds"
            class="input-group--focused"
            @click:append="show2 = !show2"
            readonly
            outlined
          ></v-text-field> -->
          <!-- sip:user//extension -->
          <div v-if="softPhoneShow">
            <!-- <v-text-field
              v-model="AddDeviceDataJson.ip"
              label="sip:"
              hint="user//extension"
              outlined
            ></v-text-field> -->
            <!-- IP//SIP URI -->
            <!-- <v-text-field
              v-model="AddDeviceDataJson.domain"
              label="IP//SIP URI"
              hint=" Url must be like this sip:support@company.com"
              outlined
            ></v-text-field> -->
            <!-- port -->
            <!-- <v-text-field
              v-model="AddDeviceDataJson.port"
              label="Port"
              hint="Default port is 5060"
              outlined
            ></v-text-field> -->
            <!-- realm -->
            <v-select
              label="Caller id number"
              :items="subscriptionNewList"
              item-text="didNumber"
              item-value="didNumber"
              v-model="AddDeviceDataJson.callerIdNumber"
              outlined
              color="primary"
              clearable
            >
            </v-select>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="11" class="py-0 pl-0">
                  <v-text-field
                    label="Username"
                    v-model="AddDeviceDataJson.userName"
                    hint="Username"
                    color="primary"
                    outlined
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="1" class="py-0 pl-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="copied"
                        v-clipboard:copy="AddDeviceDataJson.userName"
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="mt-2 pl-2"> content_copy </v-icon>
                      </v-btn>
                    </template>
                    <span>Copy</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="11" class="py-0 pl-0">
                  <v-text-field
                    label="Password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    v-model="AddDeviceDataJson.password"
                    hint="Password"
                    color="primary"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="1" class="py-0 pl-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="copied"
                        v-clipboard:copy="AddDeviceDataJson.password"
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="mt-2 pl-0"> content_copy </v-icon>
                      </v-btn>
                    </template>
                    <span>Copy</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="11" class="py-0 pl-0">
                  <v-text-field
                    v-model="AddDeviceDataJson.realm"
                    label="Realm//Sip Address"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="1" class="py-0 pl-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="copied"
                        v-clipboard:copy="AddDeviceDataJson.password"
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="mt-2 pl-2"> content_copy </v-icon>
                      </v-btn>
                    </template>
                    <span>Copy</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" text @click="close" elevation="0"> Cancel </v-btn>
          <v-btn
            color="primary"
            text
            elevation="0"
            :loading="submitLoader"
            @click="addDevice()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- sip settings for edit device -->
    <v-dialog v-model="dialogEdit" max-width="400">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Device</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0 mb-0">
          <!-- <v-text-field
            v-model="deviceDetail.device_type"
            label="Authentication Method"
            outlined
            readonly
          </v-text-field> -->
          <v-text-field
            class="pt-5"
            v-model="deviceDetail.name"
            outlined
            label="Device Name"
          ></v-text-field>
          <span v-if="deviceDetail.call_forward">
            <v-text-field
              v-model="deviceDetail.call_forward.number"
              outlined
              placeholder="Enter Complete number with country code"
              label="Number"
              v-if="cellPhoneShowOnEdit"
            ></v-text-field>
          </span>
          <v-select
            v-if="softPhoneShowOnEdit"
            label="Caller id number"
            :items="subscriptionNewList"
            item-text="didNumber"
            item-value="didNumber"
            v-model="deviceDetail.callerIdNumber"
            outlined
            color="primary"
            clearable
          >
          </v-select>
          <!-- <v-text-field
            v-model="deviceDetail.cellPhone"
            outlined
            v-if="
              deviceDetail.device_type != 'sip_device' &&
              deviceDetail.device_type != 'sip_uri' &&
              deviceDetail.device_type != 'softphone'
            "
            label="Phone Number"
          ></v-text-field> -->
          <v-col cols="12" class="py-0" v-if="softPhoneShowOnEdit">
            <v-row>
              <v-col cols="11" class="py-0 pl-0">
                <v-text-field
                  v-model="deviceDetail.sipUsername"
                  label="Username"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="1" class="py-0 pl-0">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="copied"
                      v-clipboard:copy="deviceDetail.sipUsername"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon class="mt-2 pl-2"> content_copy </v-icon>
                    </v-btn>
                  </template>
                  <span>Copy</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="py-0" v-if="softPhoneShowOnEdit">
            <v-row>
              <v-col cols="11" class="py-0 pl-0">
                <v-text-field
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  v-model="deviceDetail.sipPassword"
                  :type="show2 ? 'text' : 'password'"
                  label="Password"
                  @click:append="show2 = !show2"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="1" class="py-0 pl-0">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="copied"
                      v-clipboard:copy="deviceDetail.sipPassword"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon class="mt-2 pl-0"> content_copy </v-icon>
                    </v-btn>
                  </template>
                  <span>Copy</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="py-0" v-if="softPhoneShowOnEdit">
            <v-row>
              <v-col cols="11" class="py-0 pl-0">
                <v-text-field
                  v-model="userDetail.realm"
                  label="Realm//Sip Address"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="1" class="py-0 pl-0">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="copied"
                      v-clipboard:copy="userDetail.realm"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon class="mt-2 pl-2"> content_copy </v-icon>
                    </v-btn>
                  </template>
                  <span>Copy</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <!-- Route:sip:user//extension -->
          <!-- <v-text-field
            v-if="softPhoneShowOnEdit"
            v-model="deviceDetail.sipRoute"
            label="sip:support@company.com"
            outlined
          ></v-text-field> -->
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEdit"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="editDeviceData()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="650">
      <v-card>
        <v-card-title>
          <span class="error--text"> Warning: </span>
          <!-- <span class="pt-2"> -->
          This action will delete device from our server. Any DID linked to this
          Device will no more work. Also, if this Device is registered on any
          SIP app//SIP device, same should be removed from that configuration,
          else with wrong registration attempts, your IP will be blocked by
          system.
          <!-- </span> -->
        </v-card-title>

        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn color="error" class="px-8" outlined @click="closeDelete"
            >No</v-btn
          >
          <v-btn color="green" outlined class="px-8" @click="deleteItemConfirm"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <loading :active="isLoading" :loader="loader" />
  </section>
</template>

<script>
import numberManagerAPI from "@/services/numberManagerAPI.js";
import callFlow from "@/services/callFlowAPI.js";
import deviceAPI from "@/services/deviceAPI.js";
import Loading from "vue-loading-overlay";
import cellPhoneHeader from "./cellPhoneHeader";
import sipDeviceHeader from "./sipDeviceHeader";
import "vue-loading-overlay/dist/vue-loading.css";
import liveCallList from "@/services/liveCallAPI.js";

let deviceList = [];
let currentList = [];

export default {
  components: {
    Loading,
  },
  data() {
    return {
      editData: {},
      show1: false,
      show2: false,
      deviceShow: false,
      deviceDetail: {
        sipUsername: "",
        sipPassword: "",
        sipRoute: "",
      },
      AddDeviceDataJson: {
        device_type: "",
      },
      route: "",
      sipDeviceName: "",
      sipIp: "",
      itemidDelete: "",
      uri: "",
      itemIDSent: "",
      sipPort: "",
      device_type: "",
      dialog: false,
      dialogEdit: false,
      dialogDelete: false,
      optionSelectValues: [
        { text: "SIP Device", value: "softphone" },
        { text: "Regular Phone Number", value: "cellphone" },
      ],
      callNumberObj: {
        call_forward: {
          number: "",
        },
      },
      editedItem: "abc",
      valueNew: "",
      e1: true,
      // Realm: "",
      devicename: "",
      search: "",
      softPhoneShow: false,
      softPhoneShowOnEdit: false,
      cellPhoneShowOnEdit: false,
      callphoneShow: false,
      // totalDeviceItem: 0,
      options: {},
      submitLoader: false,
      headers: [],
      deviceListArray: [],
      // editedIndex: -1,
      // editedItem: {
      //   name: '',
      //   calories: 0,
      //   fat: 0,
      //   carbs: 0,
      //   protein: 0,
      // },
      // defaultItem: {
      //   name: '',
      //   calories: 0,
      //   fat: 0,
      //   carbs: 0,
      //   protein: 0,
      // },
      isLoading: false,
      loader: "bars",
      subscriptionNewList: [],
      // currentLiveDeviceList: [],
      tableLoader: false,
      deviceType: "",
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogEdit(val) {
      val || this.closeEdit();
    },
    options: {
      handler() {
        this.getDeviceList(this.deviceType);
      },
      deep: true,
    },
  },

  created() {
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.getDeviceList("SOFTPHONE");
    //this.getAccountDetail();
    this.getSubscriptionNew();
  },

  methods: {
    async getSubscriptionNew() {
      var payload = {
        didStatus: "DID_ACTIVATED",
        viewIndex: 1,
        viewSize: 50,
        partyId: this.userDetail.partyId,
      };
      try {
        let response = await numberManagerAPI.getDidNumberDetailSubscriptionNew(
          payload
        );
        if (response) {
          this.subscriptionNewList = response.numberList;
        }
      } catch (error) {
        console.log("====error===", error);
      }
    },
    createDevice() {
      this.dialog = true;
      this.getUsernameForDevice();
      this.callphoneShow = false;
      this.softPhoneShow = false;
      this.deviceShow = false;
      // this.AddDeviceDataJson.device_type = "softphone";
    },
    onChange(value) {
      if (value == "softphone") {
        this.softPhoneShow = true;
        this.callphoneShow = false;
        this.deviceShow = true;
      } else {
        this.softPhoneShow = false;
        this.callphoneShow = true;
        this.deviceShow = true;
      }
    },

    async getDeviceList(deviceType) {
      if (deviceType) {
        this.deviceType = deviceType;
      }
      deviceList = [];
      this.deviceListArray = [];

      this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
      var deviceDataJson = {
        accountId: this.userDetail.accountId,
        forwardType: deviceType,
      };

      this.isLoading = true;
      this.tableLoader = true;
      // const { page, itemsPerPage } = this.options;
      try {
        let response = await deviceAPI.getFilteredDevices(deviceDataJson);
        this.isLoading = false;
        if (response.deviceList) {
          // getLiveCall
          if (this.deviceType == "SOFTPHONE") {
            this.headers = sipDeviceHeader.sipDevicesHeader;
            for (let index = 0; index < response.deviceList.length; index++) {
              const element = response.deviceList[index];
              deviceList.push(element.deviceDetails);
            }
            this.geLiveDevices();
          } else {
            this.headers = cellPhoneHeader.cellPhoneHeader;
            for (let index = 0; index < response.deviceList.length; index++) {
              const element = response.deviceList[index];
              element.deviceDetails.number = element.number;
              deviceList.push(element.deviceDetails);
            }
            this.deviceListArray = deviceList;
          }
        }
        // this.totalDeviceItem = response.deviceList.length;
      } catch (error) {
        this.isLoading = false;
        this.tableLoader = false;
      }
    },

    async getUsernameForDevice() {
      this.isLoading = true;
      this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
      var UsernameForDevice = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await deviceAPI.getUsernameForDevice(UsernameForDevice);
        if (response.usernameDetails) {
          this.AddDeviceDataJson = response.usernameDetails;
          this.AddDeviceDataJson.userName = this.AddDeviceDataJson.userName;
          this.AddDeviceDataJson.password = this.AddDeviceDataJson.password;
          this.AddDeviceDataJson.realm = this.userDetail.realm;
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },

    // async getAccountDetail() {
    //   this.isLoading = true;
    //   this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    //   var getDeviceDetailsDataJson = {
    //     accountId: this.userDetail.accountId,
    //   };
    //   try {
    //     let response = await deviceAPI.getAccountDetail(
    //       getDeviceDetailsDataJson
    //     );
    //     if (response.responseMessage === "success") {
    //       this.Realm = response.accountdetails.realm;
    //     }
    //     this.isLoading = false;
    //   } catch (error) {
    //     this.isLoading = false;
    //   }
    // },

    async editItem(item) {
      this.Realm = this.userDetail.realm;
      this.isLoading = true;
      this.itemIDSent = item.id;
      if (item.device_type == "softphone") {
        this.softPhoneShowOnEdit = true;
        this.cellPhoneShowOnEdit = false;
      } else if (item.device_type == "cellphone") {
        this.softPhoneShowOnEdit = false;
        this.cellPhoneShowOnEdit = true;
      } else {
        this.softPhoneShowOnEdit = false;
        this.cellPhoneShowOnEdit = false;
      }
      this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
      var getDeviceDetailsDataJson = {
        accountId: this.userDetail.accountId,
        deviceId: item.id,
      };
      // this.getAccountDetail();
      try {
        let response = await deviceAPI.getDeviceDetails(
          getDeviceDetailsDataJson
        );
        if (response.responseMessage === "success") {
          if (response.deviceDetails.call_forward == undefined) {
            this.deviceDetail = {
              ...response.deviceDetails,
              ...this.callNumberObj,
            };
          } else {
            this.deviceDetail = response.deviceDetails;
          }
          if (this.deviceDetail.sip && this.deviceDetail.sip.username) {
            this.deviceDetail.sipUsername = this.deviceDetail.sip.username;
          }
          this.deviceDetail.sipPassword = this.deviceDetail.sip.password;
          if (
            this.deviceDetail &&
            this.deviceDetail.caller_id &&
            this.deviceDetail.caller_id.external &&
            this.deviceDetail.caller_id.external.number
          ) {
            this.deviceDetail.callerIdNumber =
              this.deviceDetail.caller_id.external.number;
          }
          this.isLoading = false;
          this.dialogEdit = true;
        }
      } catch (error) {
        this.isLoading = false;
      }
    },

    async editDeviceData() {
      this.isLoading = true;
      this.editData.accountId = this.userDetail.accountId;
      this.editData.authToken = this.userDetail.authToken;
      if (this.deviceDetail.callerIdNumber) {
        this.deviceDetail.caller_id = {
          external: {
            number: this.deviceDetail.callerIdNumber,
            name: this.deviceDetail.callerIdNumber,
          },
        };
      }
      if (this.deviceDetail.name) {
        var editDeviceData = {
          accountId: this.userDetail.accountId,
          authToken: this.userDetail.authToken,
          deviceId: this.deviceDetail.id,
          editData: { data: this.deviceDetail },
        };
        // this.editData.deviceId = this.itemIDSent;
        // this.editData.sipUriName = this.deviceDetail.name;
        // this.editData.cellPhone = this.deviceDetail.cellPhone;
        // if (this.deviceDetail.device_type == "sip_uri") {
        //   // this.editData.route = this.deviceDetail.sip.route;
        //   this.editData.userName = this.deviceDetail.sip.username;
        //   this.editData.password = this.deviceDetail.sip.password;
        // } else {
        //   this.editData.userName = this.deviceDetail.sip.username;
        //   this.editData.password = this.deviceDetail.sip.password;
        // }
        try {
          let response = await callFlow.editDeviceDetails(editDeviceData);
          setTimeout(() => {
            this.$root.$emit("SHOW_SNACKBAR", {
              text: response.messageDetail,
              color: "success",
            });
          }, 0);
          this.getDeviceList(this.deviceType);
          this.dialogEdit = false;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
        this.isLoading = false;
        setTimeout(() => {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Device Name is required.",
            color: "error",
          });
        }, 1000);
      }
    },

    closeEdit() {
      this.dialogEdit = false;
    },

    async addDevice() {
      this.submitLoader = true;
      this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
      this.AddDeviceDataJson.accountId = this.userDetail.accountId;
      this.AddDeviceDataJson.authToken = this.userDetail.authToken;
      if (this.AddDeviceDataJson.device_type) {
        if (this.AddDeviceDataJson.sipDeviceName == undefined) {
          setTimeout(() => {
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "Device Name is required.",
              color: "error",
            });
          }, 1000);
          this.submitLoader = false;
        } else {
          this.AddDeviceDataJson.deviceData = {
            data: {
              sip: {
                method: "password",
                invite_format: "username",
                username: this.AddDeviceDataJson.userName,
                password: this.AddDeviceDataJson.password,
                expire_seconds: "360",
                // route: this.device.route,
              },
              device_type: this.AddDeviceDataJson.device_type,
              name: this.AddDeviceDataJson.sipDeviceName,
              owner_id: this.userDetail.userId,
              suppress_unregister_notifications: true,
              ui_metadata: {
                version: "4.3-139",
                ui: "monster-ui",
                origin: "callflows",
              },
            },
          };
          if (this.AddDeviceDataJson.callerIdNumber) {
            this.AddDeviceDataJson.deviceData.data.caller_id = {
              external: {
                name: this.AddDeviceDataJson.callerIdNumber,
                number: this.AddDeviceDataJson.callerIdNumber,
              },
            };
          }
          if (this.AddDeviceDataJson.number) {
            this.AddDeviceDataJson.deviceData.data.call_forward = {
              number: this.AddDeviceDataJson.number,
              enabled: true,
              require_keypress: false
            };
          }
          // if (this.AddDeviceDataJson.number) {
          //   this.AddDeviceDataJson.call_forward = {};
          //   this.AddDeviceDataJson.call_forward.number =
          //     this.AddDeviceDataJson.number;
          // }

          // if (this.AddDeviceDataJson.device_type == "sip_uri") {
          //   if (sipIp != null && sipPort == null) {
          //     this.AddDeviceDataJson.route =
          //       "sip:" + sipIp + "@" + uri + ":" + "5060";
          //   } else if (sipIp == null && sipPort != null) {
          //     this.AddDeviceDataJson.route =
          //       "sip:" + sipIp + "@" + uri + ":" + sipPort;
          //   } else {
          //     this.AddDeviceDataJson.route =
          //       "sip:" + sipIp + "@" + uri + ":" + sipPort;
          //   }
          // } else {
          // }
          try {
            let response = await callFlow.addDevices(this.AddDeviceDataJson);
            if (response.responseMessage == "success") {
              setTimeout(() => {
                this.$root.$emit("SHOW_SNACKBAR", {
                  text: response.messageDetail,
                  color: "success",
                });
              }, 500);
              this.close();
              this.AddDeviceDataJson = {};
              this.softPhoneShow = false;
              this.submitLoader = false;
              this.callphoneShow = false;
              this.getDeviceList(this.deviceType);
            }
          } catch (error) {
            this.isLoading = false;
            this.submitLoader = false;
          }
        }
      } else {
        this.isLoading = false;
        this.submitLoader = false;
        setTimeout(() => {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Please select any one option!",
            color: "error",
          });
        }, 1000);
      }
    },

    async deleteItem(item) {
      this.dialogDelete = true;
      this.itemidDelete = item.id;
    },

    initialize() {},

    click() {},

    async deleteItemConfirm() {
      this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
      var deviceDataJson = {
        accountId: this.userDetail.accountId,
        deviceId: this.itemidDelete,
      };
      this.isLoading = true;
      try {
        let response = await deviceAPI.deleteDeviceDetails(deviceDataJson);
        if (response.responseMessage == "success") {
          this.dialogDelete = false;
          setTimeout(() => {
            this.$root.$emit("SHOW_SNACKBAR", {
              text: response.messageDetail,
              color: "success",
            });
          }, 0);
          this.getDeviceList(this.deviceType);
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },

    close() {
      this.dialog = false;
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    copied() {
      this.$root.$emit("SHOW_SNACKBAR", {
        text: "Copied",
        color: "success",
      });
    },
    async geLiveDevices() {
      var currentRegistrationListJoson = {
        accountId: this.userDetail.accountId,
        authToken: this.userDetail.authToken,
      };
      try {
        let response = await liveCallList.getRegistrationsData(
          currentRegistrationListJoson
        );
        if (response) {
          currentList = response.registrations;
          if (deviceList.length != 0) {
            this.deviceListArray = deviceList;
          }
          this.tableLoader = false;
        }
      } catch (error) {
        this.isLoading = false;
        this.tableLoader = false;
      }
    },
  },
  filters: {
    getDeviceStatus(deviceId) {
      const getDevice = deviceList.find((device) => device.id == deviceId);

      const getDeviceType =
        currentList &&
        currentList.find(
          (moduleObject) => moduleObject.authorizing_id == deviceId
        );
      if (getDevice && getDevice.device_type == "cellphone") {
        return "green";
      } else {
        if (getDeviceType) {
          return "green";
        } else {
          return "red";
        }
      }
    },
  },
};
</script>


<style>
.v-slide-group__content {
  align-items: center;
}

.v-card__title {
  word-break: inherit !important;
}
</style>