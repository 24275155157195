const http = require('./http');
const authToken = localStorage.getItem("authToken");

const getSpareNumbers = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getSpareNumbers?accountId=${payload.accountId}&didStatus=${payload.didStatus}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const addCallflows = (payload) => {
    return new Promise((resolve, reject) => {
        http.put('addCallflows', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};


const editCallflowsDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('editCallflowsDetails', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getCallflowsList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getCallflowsList?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getCallflowsDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getCallflowsDetails?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}&callflowId=${payload.callflowId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deleteCallflowsDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/deleteCallflowsDetails?accountId=${payload.accountId}&authToken=${payload.authToken}&callflowId=${payload.callflowId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getUsersList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getUsersList?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}&userId=${payload.userId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const addUsers = (payload) => {
    return new Promise((resolve, reject) => {
        http.put('addUsers', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const editUsersDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.put('editUsersDetails', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getDeviceDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`getDeviceDetails?accountId=${payload.accountId}&authToken=${authToken}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getMediaList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`getMediaList?accountId=${payload.accountId}&authToken=${authToken}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deleteUsersDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/deleteUsersDetails?accountId=${payload.accountId}&authToken=${payload.authToken}&usersId=${payload.usersId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getUsersDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getUsersDetails?accountId=${payload.accountId}&authToken=${payload.authToken}&usersId=${payload.usersId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getMediaDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getMediaDetails?accountId=${payload.accountId}&authToken=${payload.authToken}&mediaId=${payload.mediaId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const editMediaDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.put('editMediaDetails', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deleteMediaDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/deleteMediaDetails?accountId=${payload.accountId}&authToken=${payload.authToken}&mediaId=${payload.mediaId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const addMedia = (payload) => {
    return new Promise((resolve, reject) => {
        http.put('addMedia', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};


const getFaxboxesDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getFaxboxesDetails?accountId=${payload.accountId}&authToken=${payload.authToken}&faxboxesId=${payload.faxboxesId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const editFaxboxesDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.put('editFaxboxesDetails', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deleteFaxboxesDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/deleteFaxboxesDetails?accountId=${payload.accountId}&authToken=${payload.authToken}&faxboxesId=${payload.faxboxesId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const addFaxboxes = (payload) => {
    return new Promise((resolve, reject) => {
        http.put('addFaxboxes', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getDeviceList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getDeviceList?accountId=${payload.accountId}&authToken=${authToken}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

// const getDeviceDetails = (payload) => {
//     return new Promise((resolve, reject) => {
//         http.get(`/getFaxboxesDetails?accountId=${payload.accountId}&authToken=${payload.authToken}&deviceId=${payload.deviceId}`).then((response) => {
//             resolve(response);
//         }).catch((error) => {
//             reject(error);
//         });
//     });
// };

const editDeviceDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.put('editDevices', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deleteDeviceDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/deleteDeviceDetails?accountId=${payload.accountId}&authToken=${payload.authToken}&deviceId=${payload.deviceId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const addDevices = (payload) => {
    return new Promise((resolve, reject) => {
        http.put('addDevices', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getParticularDeviceDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`getDeviceDetails?accountId=${payload.accountId}&authToken=${authToken}&deviceId=${payload.deviceId}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getVMBoxesList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getVMBoxesList?accountId=${payload.accountId}&authToken=${authToken}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getVMBoxesDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`getVMBoxesDetails?accountId=${payload.accountId}&authToken=${authToken}&vmboxesId=${payload.vmboxesId}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deleteVMBoxesDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.delete(`/deleteVMBoxesDetails?accountId=${payload.accountId}&authToken=${payload.authToken}&vmboxesId=${payload.vmboxesId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const editVMBoxesDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('editVMBoxesDetails', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const addVMBoxes = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('addVMBoxes', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};


const getMenusList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getMenusList?accountId=${payload.accountId}&authToken=${authToken}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getMenusDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`getMenusDetails?accountId=${payload.accountId}&authToken=${authToken}&menusId=${payload.menusId}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deleteMenusDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.delete(`/deleteMenusDetails?accountId=${payload.accountId}&authToken=${payload.authToken}&menusId=${payload.menusId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const editMenusDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.put('editMenusDetails', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const addMenus = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('addMenus', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getConferencesList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`/getConferencesList?accountId=${payload.accountId}&authToken=${authToken}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getConferencesDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`getConferencesDetails?accountId=${payload.accountId}&authToken=${authToken}&conferencesId=${payload.conferencesId}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deleteConferencesDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.delete(`/deleteConferencesDetails?accountId=${payload.accountId}&authToken=${payload.authToken}&conferencesId=${payload.conferencesId}`).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
};

const editConferencesDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('editConferencesDetails', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const addConferences = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('addConferences', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getUserDevices = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`getUserDevices?accountId=${payload.accountId}&authToken=${authToken}&userId=${payload.userId}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getUsernameForDevice = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`getUsernameForDevice?accountId=${payload.accountId}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getTODList = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`getTODList?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const addTOD = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('addTOD', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const editTODDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.put('editTODDetails', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const getTODDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.get(`getTODDetails?accountId=${payload.accountId}&authToken=${localStorage.getItem("authToken")}&todId=${payload.todId}`).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const deleteTODDetails = (payload) => {
    return new Promise((resolve, reject) => {
        http.put('deleteTODDetails', payload).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        });
    });
};

module.exports = {
    getSpareNumbers,
    addCallflows,
    getCallflowsList,
    getCallflowsDetails,
    editCallflowsDetails,
    deleteCallflowsDetails,
    getUsersList,
    addUsers,
    editUsersDetails,
    getDeviceDetails,
    getParticularDeviceDetails,
    getMediaList,
    deleteUsersDetails,
    getUsersDetails,
    getMediaDetails,
    editMediaDetails,
    deleteMediaDetails,
    addMedia,
    getFaxboxesDetails,
    editFaxboxesDetails,
    deleteFaxboxesDetails,
    addFaxboxes,
    getDeviceList,
    editDeviceDetails,
    deleteDeviceDetails,
    addDevices,
    getVMBoxesList,
    getVMBoxesDetails,
    deleteVMBoxesDetails,
    editVMBoxesDetails,
    addVMBoxes,
    addMenus,
    getMenusDetails,
    getMenusList,
    deleteMenusDetails,
    editMenusDetails,
    addConferences,
    editConferencesDetails,
    deleteConferencesDetails,
    getConferencesDetails,
    getConferencesList,
    getUserDevices,
    getUsernameForDevice,
    getTODList,
    addTOD,
    getTODDetails,
    editTODDetails,
    deleteTODDetails
};

