const sipDevicesHeader = [
    {
        text: "#",
        value: "indexNo",
        sortable: false,
      },
      {
        text: "Device Name",
        align: "start",
        //sortable: false,
        value: "name",
      },
      {
        text: "User Name",
        value: "username",
      },
      {
        text: "Status",
        value: "id",
        align: "center",
      },
    //   {
    //     text: "Type",
    //     value: "device_type",
    //   },
      {
        text: "Actions",
        value: "viewAction",
        //sortable: false,
      },
]

export default { sipDevicesHeader };
